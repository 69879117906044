import { useState } from 'react'

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token')
    const userToken = JSON.parse(tokenString)
    return userToken?.token
  }

  const [token, setToken] = useState(getToken())

  const saveToken = (userToken) => {
    console.log('-- Inside saveToken. userToken is:', userToken)
    //sessionStorage.setItem('token', JSON.stringify(userToken.data.access_token))
    sessionStorage.setItem('token', JSON.stringify(userToken.detail.data.access_token))
    setToken(userToken.detail.data.access_token)
    updateUser(userToken)
  }

  const updateUser = (event) => {
    console.log('-- Inside updateUser')
    console.log('-- Access Token is: ', event.detail.data.access_token)
  }

  return {
    setToken: saveToken,
    token,
  }
}
