import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

import { useState, useEffect } from 'react'
import { subscribe, unsubscribe } from './events'
import useToken from './useToken'

import { BrowserRouter as Router } from 'react-router-dom'
import { useSelector } from 'react-redux'

//import SignUp from './authorize/SignUp'
//import Login from './authorize/Login'
import Home from './components/other components/home'
import NotFound from './components/other components/not-found'

//import { Login } from './authorize/Login'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./authorize/Login'))
const SignUp = React.lazy(() => import('./authorize/SignUp'))

// Pages
//const LogIn = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  let getUser = useSelector(({ users }) => {
    return users.authenticatedUser //Ashok Commented this and replaced with next line
    //return 'Ashok'
  })
  console.log(getUser)

  const [isOpen, setIsOpen] = useState(false)
  const [countryList, setList] = useState([])

  const updateAuthorize = () => {
    console.log('-- Inside updateAuthorize ')
  }

  useEffect(() => {
    //subscribe('logIn', (event, data) => updateAuthorize())
    //subscribe('logIn', (event, data) => AuthorizeUpdate(event, data))
    subscribe('logIn', (event, data) => setToken(event, data))

    async function fetchData() {
      const apiUrl = 'https://restcountries.com/v3.1/region/africa'
      const response = await fetch(apiUrl)
      let data = await response.json()
      setList(data)
    }
    fetchData()

    return () => {
      unsubscribe('showList')
      unsubscribe('hideList')
    }
  }, [])

  const { token, setToken } = useToken()

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

/* The below code needs be be added in return to enable suer login
<HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          {getUser ? (
            <Route path="*" name="Home" element={<DefaultLayout />} />
          ) : (
            <Route path="*" element={<Login />} />
          )}
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
*/
export default App
