import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const BaaniVideo = React.lazy(() => import('./views/baaniVideo/BaaniVideo'))
const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/range/Range'))
const Select = React.lazy(() => import('./views/forms/select/Select'))
const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

const Authorize = React.lazy(() => import('./authorize/Authorize'))
const AuthorizeUpdate = React.lazy(() => import('./authorize/AuthorizeUpdate'))
const Login = React.lazy(() => import('./authorize/Login'))
const SignUp = React.lazy(() => import('./authorize/SignUp'))

//About AWS Certifications

const AboutAWSCertifications = React.lazy(() =>
  import('./views/certifications/aws/aboutAWSCertifications/AboutAWSCertifications'),
)

const CloudPractitioner = React.lazy(() =>
  import('./views/certifications/aws/cloudPractitioner/CloudPractitioner'),
)

const SolutionArchitectAssociate = React.lazy(() =>
  import('./views/certifications/aws/solutionArchitectAssociate/SolutionArchitectAssociate'),
)

const DeveloperAssociate = React.lazy(() =>
  import('./views/certifications/aws/developerAssociate/DeveloperAssociate'),
)
const SysopsAdministrator = React.lazy(() =>
  import('./views/certifications/aws/sysopsAdministrator/SysopsAdministrator'),
)
const SolutionArchitectProfessional = React.lazy(() =>
  import('./views/certifications/aws/solutionArchitectProfessional/SolutionArchitectProfessional'),
)
const DevopsProfessional = React.lazy(() =>
  import('./views/certifications/aws/devopsProfessional/DevopsProfessional'),
)
const DatabaseSpeciality = React.lazy(() =>
  import('./views/certifications/aws/databaseSpeciality/DatabaseSpeciality'),
)
const AdvancedNetworking = React.lazy(() =>
  import('./views/certifications/aws/advancedNetworking/AdvancedNetworking'),
)
const bigDataSpeciality = React.lazy(() =>
  import('./views/certifications/aws/bigDataSpeciality/BigDataSpeciality'),
)
const dataAnalyticsSpeciality = React.lazy(() =>
  import('./views/certifications/aws/dataAnalyticsSpeciality/DataAnalyticsSpeciality'),
)
const SecuritySpeciality = React.lazy(() =>
  import('./views/certifications/aws/securitySpeciality/SecuritySpeciality'),
)
const MachineLearningSpeciality = React.lazy(() =>
  import('./views/certifications/aws/machineLearningSpeciality/MachineLearningSpeciality'),
)
const AlexaSkillBuilder = React.lazy(() =>
  import('./views/certifications/aws/alexaSkillBuilder/AlexaSkillBuilder'),
)

//Azure
const AboutAzureCertifications = React.lazy(() =>
  import('./views/certifications/azure/aboutAzureCertifications/AboutAzureCertifications'),
)
const Az104_AzureAdmin = React.lazy(() =>
  import('./views/certifications/azure/az104_AzureAdmin/Az104_AzureAdmin'),
)
const Az204_AzureDeveloper = React.lazy(() =>
  import('./views/certifications/azure/az204_AzureDeveloper/Az204_AzureDeveloper'),
)
const Az303_304_AzureSolutionArchitect = React.lazy(() =>
  import(
    './views/certifications/azure/az303_304_AzureSolutionArchitect/Az303_304_AzureSolutionArchitect'
  ),
)
const Az400_DevOps = React.lazy(() =>
  import('./views/certifications/azure/az400_DevOps/Az400_DevOps'),
)
const Az500_SecurityTech = React.lazy(() =>
  import('./views/certifications/azure/az500_SecurityTech/Az500_SecurityTech'),
)
const Az900_AzureFundamentals = React.lazy(() =>
  import('./views/certifications/azure/az900_AzureFundamentals/Az900_AzureFundamentals'),
)
const Dp900_AzureFundamentals = React.lazy(() =>
  import('./views/certifications/azure/dp900_AzureFundamentals/Dp900_AzureFundamentals'),
)
const Ai900_AzureFundamentals = React.lazy(() =>
  import('./views/certifications/azure/ai900_AzureFundamentals/Ai900_AzureFundamentals'),
)
const Dp100_AzureDataScientist = React.lazy(() =>
  import('./views/certifications/azure/dp100_AzureDataScientist/Dp100_AzureDataScientist'),
)
const Dp203_AzureDataEngineer = React.lazy(() =>
  import('./views/certifications/azure/dp203_AzureDataEngineer/Dp203_AzureDataEngineer'),
)
const Dp300_AzureDatabaseAdministrator = React.lazy(() =>
  import(
    './views/certifications/azure/dp300_AzureDatabaseAdministrator/Dp300_AzureDatabaseAdministrator'
  ),
)
const Ai102_AzureAISolutions = React.lazy(() =>
  import('./views/certifications/azure/ai102_AzureAISolutions/Ai102_AzureAISolutions'),
)
const Az202_AzureIOT = React.lazy(() =>
  import('./views/certifications/azure/az202_AzureIOT/Az202_AzureIOT'),
)

//GCP

const AboutGoogleCloudCertifications = React.lazy(() =>
  import(
    './views/certifications/gcp/aboutGoogleCloudCertifications/AboutGoogleCloudCertifications'
  ),
)
const GcpCloudDigitalLeader = React.lazy(() =>
  import('./views/certifications/gcp/gcpCloudDigitalLeader/GcpCloudDigitalLeader'),
)
const GcpAssociateCloudEngineer = React.lazy(() =>
  import('./views/certifications/gcp/gcpAssociateCloudEngineer/GcpAssociateCloudEngineer'),
)
const GcpCloudDevOpsEngineer = React.lazy(() =>
  import('./views/certifications/gcp/gcpCloudDevOpsEngineer/GcpCloudDevOpsEngineer'),
)

const GcpCloudSecurityEngineer = React.lazy(() =>
  import('./views/certifications/gcp/gcpCloudSecurityEngineer/GcpCloudSecurityEngineer'),
)
const GcpCloudNetworkEngineer = React.lazy(() =>
  import('./views/certifications/gcp/gcpCloudNetworkEngineer/GcpCloudNetworkEngineer'),
)
const GcpCloudDeveloper = React.lazy(() =>
  import('./views/certifications/gcp/gcpCloudDeveloper/GcpCloudDeveloper'),
)
const GcpProfessionalCloudArchitect = React.lazy(() =>
  import('./views/certifications/gcp/gcpProfessionalCloudArchitect/GcpProfessionalCloudArchitect'),
)
const GcpProfessionalDatabaseEngineer = React.lazy(() =>
  import(
    './views/certifications/gcp/gcpProfessionalDatabaseEngineer/GcpProfessionalDatabaseEngineer'
  ),
)
const GcpProfessionalDataEngineer = React.lazy(() =>
  import('./views/certifications/gcp/gcpProfessionalDataEngineer/GcpProfessionalDataEngineer'),
)
const AboutKubernetesCertifications = React.lazy(() =>
  import(
    './views/certifications/kubernetes/aboutKubernetesCertifications/AboutKubernetesCertifications'
  ),
)
const Cka = React.lazy(() => import('./views/certifications/kubernetes/cka/Cka'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/baaniVideo', name: 'BaaniVideo', element: BaaniVideo },
  { path: '/theme', name: 'Theme', element: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', element: Colors },
  { path: '/theme/typography', name: 'Typography', element: Typography },
  { path: '/base', name: 'Base', element: Cards, exact: true },
  { path: '/base/accordion', name: 'Accordion', element: Accordion },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', element: Cards },
  { path: '/base/carousels', name: 'Carousel', element: Carousels },
  { path: '/base/collapses', name: 'Collapse', element: Collapses },
  { path: '/base/list-groups', name: 'List Groups', element: ListGroups },
  { path: '/base/navs', name: 'Navs', element: Navs },
  { path: '/base/paginations', name: 'Paginations', element: Paginations },
  { path: '/base/placeholders', name: 'Placeholders', element: Placeholders },
  { path: '/base/popovers', name: 'Popovers', element: Popovers },
  { path: '/base/progress', name: 'Progress', element: Progress },
  { path: '/base/spinners', name: 'Spinners', element: Spinners },
  { path: '/base/tables', name: 'Tables', element: Tables },
  { path: '/base/tooltips', name: 'Tooltips', element: Tooltips },
  { path: '/buttons', name: 'Buttons', element: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', element: Buttons },
  { path: '/buttons/dropdowns', name: 'Dropdowns', element: Dropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', element: ButtonGroups },
  { path: '/charts', name: 'Charts', element: Charts },
  { path: '/forms', name: 'Forms', element: FormControl, exact: true },
  { path: '/forms/form-control', name: 'Form Control', element: FormControl },
  { path: '/forms/select', name: 'Select', element: Select },
  { path: '/forms/checks-radios', name: 'Checks & Radios', element: ChecksRadios },
  { path: '/forms/range', name: 'Range', element: Range },
  { path: '/forms/input-group', name: 'Input Group', element: InputGroup },
  { path: '/forms/floating-labels', name: 'Floating Labels', element: FloatingLabels },
  { path: '/forms/layout', name: 'Layout', element: Layout },
  { path: '/forms/validation', name: 'Validation', element: Validation },
  { path: '/icons', exact: true, name: 'Icons', element: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', element: Flags },
  { path: '/icons/brands', name: 'Brands', element: Brands },
  { path: '/notifications', name: 'Notifications', element: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', element: Alerts },
  { path: '/notifications/badges', name: 'Badges', element: Badges },
  { path: '/notifications/modals', name: 'Modals', element: Modals },
  { path: '/notifications/toasts', name: 'Toasts', element: Toasts },
  { path: '/widgets', name: 'Widgets', element: Widgets },

  {
    path: '/authorize/Authorize',
    name: 'Authorize',
    element: Authorize,
  },
  {
    path: '/authorize/AuthorizeUpdate',
    name: 'AuthorizeUpdate',
    element: AuthorizeUpdate,
  },
  {
    path: '/authorize/Login',
    name: 'Login',
    element: Login,
  },
  {
    path: '/authorize/SignUp',
    name: 'SignUp',
    element: SignUp,
  },

  {
    path: '/certifications/aws/AboutAWSCertifications',
    name: 'AboutAWSCertifications',
    element: AboutAWSCertifications,
  },
  {
    path: '/certifications/aws/CloudPractitioner',
    name: 'CloudPractitioner',
    element: CloudPractitioner,
  },

  {
    path: '/certifications/aws/SolutionArchitectAssociate',
    name: 'SolutionArchitectAssociate',
    element: SolutionArchitectAssociate,
  },
  {
    path: '/certifications/aws/DeveloperAssociate',
    name: 'DeveloperAssociate',
    element: DeveloperAssociate,
  },
  {
    path: '/certifications/aws/SysopsAdministrator',
    name: 'SysopsAdministrator',
    element: SysopsAdministrator,
  },
  {
    path: '/certifications/aws/SolutionArchitectProfessional',
    name: 'SolutionArchitectProfessional',
    element: SolutionArchitectProfessional,
  },
  {
    path: '/certifications/aws/DevopsProfessional',
    name: 'DevopsProfessional',
    element: DevopsProfessional,
  },
  {
    path: '/certifications/aws/DatabaseSpeciality',
    name: 'DatabaseSpeciality',
    element: DatabaseSpeciality,
  },
  {
    path: '/certifications/aws/bigDataSpeciality',
    name: 'bigDataSpeciality',
    element: bigDataSpeciality,
  },
  {
    path: '/certifications/aws/dataAnalyticsSpeciality',
    name: 'dataAnalyticsSpeciality',
    element: dataAnalyticsSpeciality,
  },
  {
    path: '/certifications/aws/AdvancedNetworking',
    name: 'AdvancedNetworking',
    element: AdvancedNetworking,
  },
  {
    path: '/certifications/aws/SecuritySpeciality',
    name: 'SecuritySpeciality',
    element: SecuritySpeciality,
  },
  {
    path: '/certifications/aws/MachineLearningSpeciality',
    name: 'MachineLearningSpeciality',
    element: MachineLearningSpeciality,
  },
  {
    path: '/certifications/aws/AlexaSkillBuilder',
    name: 'AlexaSkillBuilder',
    element: AlexaSkillBuilder,
  },
  {
    path: '/certifications/azure/AboutAzureCertifications',
    name: 'AboutAzureCertifications',
    element: AboutAzureCertifications,
  },
  {
    path: '/certifications/azure/Az104_AzureAdmin',
    name: 'Az104_AzureAdmin',
    element: Az104_AzureAdmin,
  },
  {
    path: '/certifications/azure/Az204_AzureDeveloper',
    name: 'Az204_AzureDeveloper',
    element: Az204_AzureDeveloper,
  },
  {
    path: '/certifications/azure/Az303_304_AzureSolutionArchitect',
    name: 'Az303_304_AzureSolutionArchitect',
    element: Az303_304_AzureSolutionArchitect,
  },
  {
    path: '/certifications/azure/Az400_DevOps',
    name: 'Az400_DevOps',
    element: Az400_DevOps,
  },
  {
    path: '/certifications/azure/Az500_SecurityTech',
    name: 'Az500_SecurityTech',
    element: Az500_SecurityTech,
  },
  {
    path: '/certifications/azure/Az900_AzureFundamentals',
    name: 'Az900_AzureFundamentals',
    element: Az900_AzureFundamentals,
  },
  {
    path: '/certifications/azure/Dp900_AzureFundamentals',
    name: 'Dp900_AzureFundamentals',
    element: Dp900_AzureFundamentals,
  },
  {
    path: '/certifications/azure/Ai900_AzureFundamentals',
    name: 'Ai900_AzureFundamentals',
    element: Ai900_AzureFundamentals,
  },
  {
    path: '/certifications/azure/Dp100_AzureDataScientist',
    name: 'Dp100_AzureDataScientist',
    element: Dp100_AzureDataScientist,
  },

  {
    path: '/certifications/azure/Dp203_AzureDataEngineer',
    name: 'Dp203_AzureDataEngineer',
    element: Dp203_AzureDataEngineer,
  },
  {
    path: '/certifications/azure/Dp300_AzureDatabaseAdministrator',
    name: 'Dp300_AzureDatabaseAdministrator',
    element: Dp300_AzureDatabaseAdministrator,
  },
  {
    path: '/certifications/azure/Ai102_AzureAISolutions',
    name: 'Ai102_AzureAISolutions',
    element: Ai102_AzureAISolutions,
  },
  {
    path: '/certifications/azure/Az202_AzureIOT',
    name: 'Az202_AzureIOT',
    element: Az202_AzureIOT,
  },
  {
    path: '/certifications/gcp/AboutGoogleCloudCertifications',
    name: 'AboutGoogleCloudCertifications',
    element: AboutGoogleCloudCertifications,
  },
  {
    path: '/certifications/gcp/GcpCloudDigitalLeader',
    name: 'GcpCloudDigitalLeader',
    element: GcpCloudDigitalLeader,
  },
  {
    path: '/certifications/gcp/GcpAssociateCloudEngineer',
    name: 'GcpAssociateCloudEngineer',
    element: GcpAssociateCloudEngineer,
  },
  {
    path: '/certifications/gcp/GcpCloudDevOpsEngineer',
    name: 'GcpCloudDevOpsEngineer',
    element: GcpCloudDevOpsEngineer,
  },
  {
    path: '/certifications/gcp/GcpProfessionalDatabaseEngineer',
    name: 'GcpProfessionalDatabaseEngineer',
    element: GcpProfessionalDatabaseEngineer,
  },
  {
    path: '/certifications/gcp/GcpCloudSecurityEngineer',
    name: 'GcpCloudSecurityEngineer',
    element: GcpCloudSecurityEngineer,
  },

  {
    path: '/certifications/gcp/GcpCloudNetworkEngineer',
    name: 'GcpCloudNetworkEngineer',
    element: GcpCloudNetworkEngineer,
  },
  {
    path: '/certifications/gcp/GcpCloudDeveloper',
    name: 'GcpCloudDeveloper',
    element: GcpCloudDeveloper,
  },
  {
    path: '/certifications/gcp/GcpProfessionalCloudArchitect',
    name: 'GcpProfessionalCloudArchitect',
    element: GcpProfessionalCloudArchitect,
  },
  {
    path: '/certifications/gcp/GcpProfessionalDataEngineer',
    name: 'GcpProfessionalDataEngineer',
    element: GcpProfessionalDataEngineer,
  },
  {
    path: '/certifications/kubernetes/AboutKubernetesCertifications',
    name: 'AboutKubernetesCertifications',
    element: AboutKubernetesCertifications,
  },
  {
    path: '/certifications/kubernetes/Cka',
    name: 'Cka',
    element: Cka,
  },
]

export default routes
