// Note: All authentication cases are defined here...!

import { createStore } from 'redux'

import {
  SIGN_UP_USER,
  LOG_IN_USER,
  LOG_IN_USER_OAUTH,
  LOG_OUT_USER,
} from '../constant/action-types'
import swal from 'sweetalert'
import Cookies from 'js-cookie'
import { ConsoleView } from 'react-device-detect'
import Random from 'random-id'

const INIT_STATE = {
  sidebarShow: true,
  sidebarFlag: false,
}
let session_id = ''

const authReducer = (state = INIT_STATE, action) => {
  console.log('-- authReducer is called')
  console.log('-- state:', state)
  console.log('-- action:', action)
  console.log('-- state.sidebarShow: ', state.sidebarShow)

  //The below code is added to manage a session for user who is not logged in
  //session_id = Random(24)
  //Cookies.set('session_id', session_id)
  session_id = Cookies.get('session_id', { maxAge: 3600 })

  console.log('-- session_id is: ', session_id)
  if (session_id === undefined) {
    console.log('-- session_id is undefined.')
    session_id = Random(24)
    Cookies.set('session_id', session_id, { expires: 14 })
  }
  //const { ...rest } = props
  // Note: Function to check duplicate user...!
  const checkDuplicateUser = (email) => {
    let duplicateFlag = false
    let allUsersClone = state.usersArr

    for (let i = 0; i < allUsersClone.length; i++) {
      // console.log(allUsersClone[i].email);

      if (email === allUsersClone[i].email) {
        duplicateFlag = true
      }
    }

    return duplicateFlag
  }

  switch (action.type) {
    /********** USER SIGN_UP AREA **********/
    case SIGN_UP_USER:
      let duplicateUser = checkDuplicateUser(action.payload.email)
      // console.log(duplicateUser);
      let usersClone = state.usersArr

      if (duplicateUser) {
        swal({
          title: 'Error! ⚠️',
          text: 'The email you entered is already registered by another account. Please use another email address!',
          icon: 'warning',
          button: 'Try Again',
        })
      } else {
        usersClone.push(action.payload)
        console.log(usersClone)
        swal({
          title: 'Registered Successfully!',
          text: 'You have signed up sucessfully!',
          icon: 'success',
          button: 'Ok!',
        })

        return {
          ...state,
          usersArr: usersClone,
        }
      }

    case LOG_IN_USER_OAUTH:
      // console.log(action.payload);
      let userCloneForLogIn_OAuth = state.usersArr
      let userEmail_OAuth = action.payload.email
      let userPassword_OAuth = action.payload.password
      let message_OAuth
      let userFlag_OAuth = false
      let targetUser_OAuth = action.payload

      //Ashok Write Custom Code Here
      console.log('-- From LOG_IN_USER_OAUTH, User data is ', targetUser_OAuth)
      Cookies.set('user', targetUser_OAuth)
      swal({
        title: 'Logged In Successfully!',
        text: 'You have logged in sucessfully!',
        icon: 'success',
        button: 'Ok!',
      })

      return {
        ...state,
        authenticatedUser: targetUser_OAuth,
      }

    /********** USER LOG_IN AREA **********/
    case LOG_IN_USER:
      // console.log(action.payload);
      let userCloneForLogIn = state.usersArr
      let userEmail = action.payload.email
      let userPassword = action.payload.password
      let message
      let userFlag = false
      let targetUser

      for (let i = 0; i < userCloneForLogIn.length; i++) {
        let usersList = userCloneForLogIn[i]

        if (userEmail === usersList.email && userPassword === usersList.password) {
          targetUser = userCloneForLogIn[i]
          message = 'Authorized User!'
          userFlag = true
          break
        } else if (userEmail === usersList.email && userPassword != usersList.password) {
          message = 'Email is correct, But Password is Incorrect!'
          userFlag = false
          break
        } else if (userEmail != usersList.email && userPassword != usersList.password) {
          message = 'User does not exist!'
          userFlag = false
        }
      }

      // console.log(message);
      // console.log(userFlag);

      if (userFlag) {
        // Note: Set user in cookies...!

        console.log('-- targetUser is: ', targetUser)
        Cookies.set('user', targetUser)
        swal({
          title: 'Logged In Successfully!',
          text: 'You have logged in sucessfully!',
          icon: 'success',
          button: 'Ok!',
        })
      } else {
        swal({
          title: 'Something Went Wrong!',
          text: `${message}`,
          icon: 'error',
          button: 'Try Again!',
        })
      }

      return {
        ...state,
        authenticatedUser: targetUser,
      }

    /********** USER LOG_OUT AREA **********/
    case LOG_OUT_USER:
      swal({
        title: 'Good Bye!',
        text: 'You have logged out sucessfully!',
        icon: 'success',
        button: 'Bye!',
      })

      // Note: Removing user information from local storage and cookies...!
      Cookies.remove('user')

      return {
        ...state,
        authenticatedUser: null,
      }

    case 'set': {
      //console.log('-- Inside Set. ', event.sidebarShow)

      //state.sidebarShow = false
      /* if (state.sidebarShow === false) {
        state.sidebarShow = true
      } */

      /*if (state.sidebarShow === false) {
        state.sidebarShow = true
      } else {
        state.sidebarShow = false
      }*/

      //state.sidebarShow = action.sidebarShow
      //alert('Set is called')
      state.sidebarShow = !state.sidebarShow

      //alert('sidebarShow: ', state.sidebarShow, '   sidebarFlag: ', state.sidebarFlag)
      /* if (state.sidebarFlag === true) {
        state.sidebarFlag = false
        //alert('sidebarFlag === true')
      } else {
        if (state.sidebarShow === true) {
          //alert('sidebarShow === true')
          state.sidebarShow = false
          state.sidebarFlag = true
        } else {
          //alert('sidebarShow === false')
          state.sidebarShow = true
          state.sidebarFlag = true
        }
      } */

      return {
        ...state,
      }
    }

    default:
      console.log('-- Inside default')
      return state
  }
}

export default authReducer
