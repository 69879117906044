import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
//import store from './store'

import { PersistGate } from 'redux-persist/integration/react'
//import { persistor } from './store/index'
import AppRoutes from './routes'
//import store from './store.js'

//import store from './store/reducer/index'
import { createStore } from 'redux'
import { applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'

import authReducer from './store/reducer/index'
//import authReducer from './store/reducer/auth-reducer'
export const store = createStore(authReducer, {}, applyMiddleware(thunk))
//export default store

export const persistor = persistStore(store)

// Note: Main store file...!

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

/*

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
*/
